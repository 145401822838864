@import "~react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}

html {
  font-size: 16px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#root .google-map-container {
  height: 400px;
  border-radius: 0.25rem;
}
